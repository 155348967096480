
@media (max-width: 765px) {
    .custom-size {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  
  @media (max-width: 450px) {
    .custom-size {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }